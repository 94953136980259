import { createApp } from 'vue'
import App from './App.vue'

//store(vuex)
import store from './store/state'

//axios
import axios from 'axios'
axios.defaults.baseURL = store.serverBaseUrl + '/api'
axios.defaults.headers.common['Content-Type'] = 'Application/json'
axios.defaults.headers.common.Accept = 'Application/json'
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')

//router
import router from './router.js'
import './assets/sass/index.sass'
//apex chart
import VueApexCharts from 'vue3-apexcharts';
//notif
import Notifications from '@kyvg/vue3-notification'
//date
import Vue3PersianDatetimePicker from 'vue3-persian-datetime-picker'
//icon
import 'boxicons/css/boxicons.css'



const app= createApp(App)
app.use('apexchart', VueApexCharts)
app.component('DatePicker', Vue3PersianDatetimePicker)
app.use (router)
app.use(Notifications)


app.mount('#app')
