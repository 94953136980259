import {createRouter,createWebHashHistory} from "vue-router";
// import * as path from "path";
const routes=[
    { path:"/",
        name:"LoginPage" ,
        component: () => import('@/components/auth/Login.vue')
    },
    { path:"/Delete_popup",
        name:"Delete_popup" ,
        component: () => import('@/views/popup/Delete_popup'),
    },
    { path:"/dashboard",
        name:"dashboardPage" ,
        component: () => import('@/layouts/Dashboard'),
        children:[
            { path:"/test_code",
                name:"test_code" ,
                component: () => import('@/views/test_code')
            },
            { path:"/Home",
                name:"HomePAge" ,
                component: () => import('@/views/HomePAge')
            },
            { path:"/Management_data",
                name:"Management_data" ,
                component: () => import('@/views/management/Management_data')
            },
            { path:"/System_performance_history",
                name:"System_performance_history" ,
                component: () => import('@/views/history/System_performance_history')
            },
            { path:"/Check_system",
                name:"Check_system" ,
                component: () => import('@/views/checkSystem/Check_system')
            },
            { path:"/UserManagement",
                name:"UserManagement" ,
                component: () => import('@/views/user/UserManagement')
            },
            { path:"/About_us",
                name:"About_us" ,
                component: () => import('@/views/info/About_us')
            },
            { path:"/ListProject",
                name:"ListProject" ,
                component: () => import('@/views/project/ListProject')
            },
            { path:"/ParametersDetail/:id",
                name:"ParametersDetail" ,
                component: () => import('@/views/project/ParametersDetail')
            },
            { path:"/TypeOfProject",
                name:"TypeOfProject" ,
                component: () => import('@/views/management/TypeOfProject')
            },
        ]
    },
]



const router=createRouter({
    history:createWebHashHistory(),
    base: process.env.BASE_URL,
    routes
})


export default router;